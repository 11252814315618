exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-example-demo-js": () => import("./../../../src/pages/example/demo.js" /* webpackChunkName: "component---src-pages-example-demo-js" */),
  "component---src-pages-example-hello-js": () => import("./../../../src/pages/example/hello.js" /* webpackChunkName: "component---src-pages-example-hello-js" */),
  "component---src-pages-features-drawer-js": () => import("./../../../src/pages/features/drawer.js" /* webpackChunkName: "component---src-pages-features-drawer-js" */),
  "component---src-pages-hello-js": () => import("./../../../src/pages/hello.js" /* webpackChunkName: "component---src-pages-hello-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-first-post-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/first-post.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-first-post-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-page-2-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/page2.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-page-2-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-10-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page10.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-10-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-2-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page2.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-2-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-3-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page3.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-3-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-4-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page4.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-4-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-5-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page5.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-5-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-6-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page6.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-6-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-7-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page7.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-7-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-8-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page8.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-8-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-9-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/pages/page9.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-page-9-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-second-post-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/content/second-post.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-second-post-mdx" */),
  "component---src-pages-movies-index-js": () => import("./../../../src/pages/movies/index.js" /* webpackChunkName: "component---src-pages-movies-index-js" */),
  "component---src-templates-genre-js": () => import("./../../../src/templates/genre.js" /* webpackChunkName: "component---src-templates-genre-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-movie-js": () => import("./../../../src/templates/movie.js" /* webpackChunkName: "component---src-templates-movie-js" */)
}

